import React, { Component, Fragment } from "react";
/** Importing functional components */
import DefaultPage from "../layout/DefaultPage";
import { graphql } from "gatsby";
import Img from "gatsby-image";
/** Importing styled components */
import styled from "styled-components";

import {
  BlogLayout,
  GeneralContainer,
  PostRow,
  H1,
  Paragraph,
  RootBorder
} from "../components/StyledComponent";

import { Col } from "react-flexbox-grid";
import { ChevronLeft, ChevronRight, X } from "react-feather";

class GalleryPage extends Component {
  state = {
    showLightbox: false,
    selectedImage: 0,
    gallery: ""
  };

  componentDidMount = () => {
    window.addEventListener("keyup", this.handleKeyUp, false);
  };

  componentWillUnmount = () => {
    window.removeEventListener("keyup", this.handleKeyUp, false);
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      showLightbox: !this.state.showLightbox,
      selectedImage: index
    });
  };

  closeModal = () => {
    this.setState({ showLightbox: false });
  };

  goBack = () => {
    this.setState({ selectedImage: this.state.selectedImage - 1 });
  };

  goForward = () => {
    this.setState({ selectedImage: this.state.selectedImage + 1 });
  };

  handleKeyUp = e => {
    e.preventDefault();
    const { keyCode } = e;
    if (this.state.showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (this.state.selectedImage > 0) {
          this.setState({ selectedImage: this.state.selectedImage - 1 });
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (
          this.state.selectedImage <
          this.props.data.getData.frontmatter.gallery.length - 1
        ) {
          this.setState({ selectedImage: this.state.selectedImage + 1 });
        }
      }
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false });
      }
    }
  };

  render() {
    const { gallery } = this.props.data.getData.frontmatter;

    const { showLightbox, selectedImage } = this.state;

    return (
      <Fragment>
      <DefaultPage pageTitle={this.props.data.getData.frontmatter.title}>
      <BlogLayout>
        <RootBorder />
        <GeneralContainer minHeight="calc(100vh - 28rem)">
          <PostRow style={{paddingTop: "2rem"}}>
            <Col xs={12}>
              <H1 color="var(--secondary)">{this.props.data.getData.frontmatter.title}</H1>
            </Col>
          </PostRow>
          <PostRow style={{ paddingBottom: '2rem'}}>
                <Col xs={12} lg={6}>
                  <Paragraph color="var(--black)">
                    {this.props.data.getData.frontmatter.description}
                  </Paragraph>
                </Col>
              <Col xs={12} lg={6}>
        <Gallery>
          {gallery.map((el, i) => {
            if (el.image) {
              return (
                <GalleryItem key={i}>
                  <a
                    href={el.image.childImageSharp.fluid.src}
                    alt="Car Image"
                    onClick={e => this.handleClick(e, i)}
                  >
                    <StyledImg fluid={el.image.childImageSharp.fluid} />
                  </a>
                </GalleryItem>
              );
            } else {
              return null;
            }
          })}
        </Gallery>
        </Col>
              </PostRow>
        </GeneralContainer>
        </BlogLayout>
        </DefaultPage>
        <LightboxModal
          visible={showLightbox}
          onKeyUp={e => this.handleKeyDown(e)}
        >
          <LightboxContent>
            <Img fluid={gallery[selectedImage].image.childImageSharp.fluid} />
            <Controls>
              {gallery[selectedImage].name ? <small>{gallery[selectedImage].name}</small> : <small>Sin nombre</small>}
              <Button onClick={this.closeModal}><X size={24} /></Button>
              <LeftRight>
                <Button onClick={this.goBack} disabled={selectedImage === 0}>
                  <ChevronLeft size={24} />
                </Button>
                <Button
                  onClick={this.goForward}
                  disabled={selectedImage === gallery.length - 1}
                >
                  <ChevronRight size={24} />
                </Button>
              </LeftRight>
            </Controls>
          </LightboxContent>
        </LightboxModal>
        </Fragment>
    );
  }
}

export const galleryQuery = graphql`
  query getGalleryData($id: String!) {
    getData: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        gallery {
          name
          image {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`;

const StyledImg = styled(Img)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%; // or whatever
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1100px) {
    grid-template-columns: repeat(5, 1fr);
  }
  grid-gap: 15px;
  .gatsby-image-outer-wrapper {
    height: 100%;
  }
`;

const GalleryItem = styled.div`
  position: relative;
`;

const Button = styled.button`
  margin-top: 1rem;
  width: 3rem;
  height: 3rem;
  appearance: none;
  border: 1px solid var(--secondary);
  border-radius: 40px;
  background: var(--black);
  cursor: pointer;
  &:hover {
    background: var(--primary);
  }
  svg {
    color: var(--secondary);
  }
`;

const LightboxModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  opacity: ${props => (props.visible ? "1" : "0")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`;
const LightboxContent = styled.div`
  margin: 15px;
  max-width: 580px;
  width: 100%;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  > small {
    position: absolute;
    top: -49px;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: var(--white);
    background: var(--black);
    padding: .5rem 1rem;
    max-height: 98px;
    min-height: 98px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
`;

const LeftRight = styled.div`
  button:first-child {
    margin-right: 10px;
  }
`;

export default GalleryPage;
